:root {
  --header-height: 85px;
  --footer-height: 40px;
  --sidebar-width: 250px;
}

#layout {
  display: grid;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  grid-template-rows: var(--header-height) 1fr var(--footer-height);
  grid-template-columns: var(--sidebar-width) 1fr;
  grid-template-areas:
    "header header"
    "sidebar main"
    "footer footer"
}

#layout > header {
  grid-area: header;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  height: var(--header-height);
  justify-content: end;
  align-items: center;
  z-index: 50;
  /* box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  border-radius: 88px;
  background-color: #F19920; */
}

#layout > .sidebar {
  grid-area: sidebar;
  position: fixed;
  height: 100vh;
  top: 0;
  overflow-y: auto;
  max-width: var(--sidebar-width);
  background-color: white;
}

.header > .logo-container {
  width: calc(var(--sidebar-width) - 80px);
  display: flex;
}

#layout > footer {
  grid-area: footer;
  @apply bg-slate-300;
}

#layout > main {
  grid-area: main;
  background-color: #f5f5f5;
  width: calc(100vw - var(--sidebar-width));
  overflow: auto;
}
@media screen and (max-width: 768px) {
  #layout > .sidebar {
    display: none;
  }
  #layout {
    grid-template-areas:
      "header header"
      "main main"
      "footer footer" ;
  }
  #layout > .sidebar-footer {
    display: none;
  }

  #layout > main {
    width: 100vw;
    overflow: scroll;
  }
}